import React, { Component } from 'react';
export class Contact extends Component {

    render() {
        return (
            <div>
                <h5>Chcesz się z nami skontaktować? Napisz do nas na:</h5>
                <p>info@stocked.pl</p>
            </div>
        )
    }
}