import React, { Component } from 'react';
export class PrivacyPolicy extends Component {

    render() {
        return (
            <div>
                <h2>Polityka prywatności</h2>
                <p>
                    Drogi Użytkowniku i Usługobiorco,
                </p>
                <p>
                    Dokładamy wszelkich starań, aby zapewnić bezpieczeństwo i poufność Twoich danych osobowych. Dbamy
                    o Twoją prywatność zarówno wtedy, gdy odwiedzasz nasz serwis internetowy, rejestrujesz w nim konto
                    i korzystasz z naszych usług, jak również gdy kontaktujesz się z nami telefonicznie, mailowo lub za pośrednictwem chatu online, zapisujesz się na newsletter czy odwiedzasz nasze kanały w mediach społecznościowych . Działamy zgodnie z literą prawa, w tym zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (zwanym „RODO”).
                </p>
                <p>
                    W niniejszym dokumencie chcemy przedstawić Ci najistotniejsze informacje na temat przetwarzania Twoich danych osobowych. Dla uproszczenia zestawiliśmy je w formie pytań i odpowiedzi. Wszystko po to, abyś dowiedział się w jakim celu, na jakiej podstawie i jak długo przetwarzamy Twoje dane, a także kto może mieć do nich dostęp oraz jakie przysługują Ci prawa.
                </p>

                <br />

                <h5>JAK POZYSKUJEMY TWOJE DANE OSOBOWE?</h5>
                <p>
                    Korzystając z serwisu Stocked.pl (dalej: „Serwis”) możesz być poproszony o przekazanie swoich danych osobowych. Podanie danych jest dobrowolne, ale w pewnych sytuacjach może okazać się niezbędne. Przykładowo, bez podania adresu e-mail nie otrzymasz od nas newsletter’a, nie zarejestrujemy Twojego konta, ani nie udzielimy Ci mailowo odpowiedzi na pytanie zadane za pośrednictwem formularza kontaktowego.
                </p>
                <p>
                    Niektóre dane zbierane są automatycznie za pomocą plików cookies podczas Twojej wizyty w Serwisie (np. adres IP, typ przeglądarki, rodzaj systemu operacyjnego itp.). Służą one administrowaniu stroną www, zapewnieniu obsługi hostingowej oraz tworzeniu odpowiednich treści marketingowych. Możesz jednak dowolnie blokować i ograniczać instalowanie plików cookies za pomocą ustawień swojej przeglądarki lub przy pomocy innych (darmowych) rozwiązań.
                </p>

                <br />

                <h5>KTO JEST ADMINISTRATOREM TWOICH DANYCH OSOBOWYCH?</h5>
                <p>
                    Administratorem Twoich danych osobowych jest MINDPEAK MACIEJ MUCHA z siedzibą w Warszawie NIP: 693-192-37-73,  REGON 368134801
                </p>
                <p>
                    W razie pytań i wątpliwości możesz skontaktować się z nami drogą elektroniczną pod następującym adresem e-mail: info@stocked.pl
                </p>

                <br />

                <h5>W JAKIM CELU, NA JAKIEJ PODSTAWIE PRAWNEJ I JAK DŁUGO PRZETWARZAMY TWOJE DANE?</h5>
                <p>
                    Przetwarzamy Twoje dane osobowe w celu: 
                </p>
                <ol>
                    <li>
                        zawarcia i wykonania umowy o świadczenie usług (zarejestrowanie i prowadzenie konta usługobiorcy, składanie zamówień na usługi bezpłatne i odpłatne, realizacja umowy):
                        <ul>
                            <li>
                                podstawą prawną jest niezbędność przetwarzania do wykonania umowy lub podjęcia działań na żądanie osoby, której dane dotyczą, przed zawarciem umowy (art. 6 ust. 1 lit. b RODO),
                            </li>
                            <li>
                                dane przetwarzane będą do czasu zakończenia świadczenia usługi (usunięcie konta usługobiorcy, rozwiązanie umowy o świadczenie usług);
                            </li>
                        </ul>
                    </li>
                    <li>
                        wypełnienia obowiązków podatkowych (wystawianie faktur, przechowywanie dokumentacji księgowej):
                        <ul>
                            <li>
                                podstawą prawną jest ciążący na nas obowiązek prawny (art. 6 ust. 1 lit. c RODO),
                            </li>
                            <li>
                                dane przetwarzane będą do czasu upływu terminów przedawnienia zobowiązań podatkowych;
                            </li>
                        </ul>
                    </li>
                    <li>
                        wypełnienia obowiązków w zakresie ochrony danych osobowych:
                        <ul>
                            <li>
                                podstawą prawną jest ciążący na nas obowiązek prawny (art. 6 ust. 1 lit. c RODO),
                            </li>
                            <li>
                                dane przetwarzane będą do czasu upływu terminów przedawnienia roszczeń z tytułu naruszenia przepisów o ochronie danych osobowych;
                            </li>
                        </ul>
                    </li>
                    <li>
                        ustalenia, dochodzenia i obrony ewentualnych roszczeń:
                        <ul>
                            <li>
                                podstawą prawną jest nasz uzasadniony prawnie interes (art. 6 ust. 1 lit. f RODO), który mamy w tym, aby podjąć działania mające na celu ochronę naszych praw w postępowaniu przed sądami i innymi organami państwowymi,
                            </li>
                            <li>
                                dane przetwarzane będą do czasu upływu terminów przedawnienia roszczeń wynikających z obowiązujących przepisów prawa;
                            </li>
                        </ul>
                    </li>
                    <li>
                        zapewnienia prawidłowego funkcjonowania Serwisu oraz analizy aktywności użytkowników Serwisu:
                        <ul>
                            <li>
                                podstawą prawną jest nasz uzasadniony prawnie interes (art. 6 ust. 1 lit. f RODO), który mamy
                                w tym, aby prowadzić analizy i statystyki korzystania z  poszczególnych funkcjonalności Serwisu (np. cookies Google Analytics, Piksel Facebooka),
                            </li>
                            <li>
                                dane przetwarzane będą do czasu skutecznego wniesienia sprzeciwu lub osiągnięcia celu przetwarzania;
                            </li>
                        </ul>
                    </li>
                    <li>
                        prowadzenia fanpage’a w portalu Facebook i grupy pod nazwą Stocked oraz wchodzenie w interakcje z użytkownikami ww. mediów społecznościowych:
                        <ul>
                            <li>
                                podstawą prawną jest nasz uzasadniony prawnie interes (art. 6 ust. 1 lit. f RODO), który mamy
                                w tym, aby promować Serwis i dostosowywać jego funkcjonalności do aktualnych potrzeb,
                            </li>
                            <li>
                                dane przetwarzane będą do czasu upływu terminów przedawnienia roszczeń wynikających
                                z obowiązujących przepisów prawa;
                            </li>
                        </ul>
                    </li>
                    <li>
                        udzielania Ci odpowiedzi na pytania skierowane do nas telefoniczne lub drogą elektroniczną,
                        w tym również za pośrednictwem formularza dostępnego z pośrednictwem Serwisu i czatu online:
                        <ul>
                            <li>
                                podstawą prawną jest nasz uzasadniony prawnie interes (art. 6 ust. 1 lit. f RODO), który mamy
                                w tym, aby komunikować się z naszymi klientami i odpowiadać na pytania naszych potencjalnych klientów lub innych osób zainteresowanych naszymi produktami i usługami,
                            </li>
                            <li>
                                dane przetwarzane będą do czasu upływu terminów przedawnienia roszczeń wynikających
                                z obowiązujących przepisów prawa;
                            </li>
                        </ul>
                    </li>
                    <li>
                        marketingowym (promocja naszych towarów i usług):
                        <ul>
                            <li>
                                podstawą prawną jest nasz uzasadniony prawnie interes (art. 6 ust. 1 lit. f RODO), który mamy
                                w tym, utrzymywać z relacje biznesowe z klientami oraz badać ich satysfakcję, dbać o własne interesy i wizerunek lub odpowiednio dobrowolna zgoda osoby, która ją wyraziła w określonym celu (art. 6 ust. 1 lit. a RODO),
                            </li>
                            <li>
                                dane przetwarzane będą do czasu do czasu skutecznego wniesienia sprzeciwu lub osiągnięcia celu przetwarzania a w przypadku gdy podstawą przetwarzania jest zgoda - do czasu cofnięcia zgody (przy czym wycofanie zgody nie wpływa na legalność przetwarzania danych przez jej wycofaniem)
                            </li>
                        </ul>
                    </li>
                </ol>
                <p><b>PAMIĘTAJ!</b></p>
                <p>
                    Dane osobowe przetwarzamy tak długo, jak jest to konieczne, aby osiągnąć wskazane powyżej cele, chyba
                    że złożysz ważny i prawidłowy wniosek o usunięcie Twoich danych osobowych. Ponadto, okres przetwarzania może być uzależniony od treści obowiązujących nas przepisów prawa, np. w przypadku przechowywania dokumentów finansowych czy terminów dochodzenia roszczeń.
                </p>

                <br />
                <h5>KTO MOŻE BYĆ ODBIORCĄ TWOICH DANYCH OSOBOWYCH?</h5>

                <p>W niektórych sytuacjach, jeśli okaże się to niezbędne do realizacji celów przetwarzania danych, korzystamy ze wsparcia i pomocy podmiotów zewnętrznych. Każdorazowo jednak, przed przekazaniem danych osobowych, wymagamy od ich odbiorców zagwarantowania odpowiedniej ich ochrony i poufności.</p>

                <p>Odbiorcami Twoich danych osobowych mogą być:</p>
                <ol>
                    <li>
                        podmioty współuczestniczące w wykonaniu przez nas umów, np.: biuro rachunkowe, dostawcy usług informatycznych, dostawcy usług hostingowych, dostawcy systemów płatności,
                    </li>
                    <li>
                        podmioty, z których pomocy i usług korzystamy w zakresie prowadzonej przez nas działalności gospodarczej na podstawie odrębnych umów, np.:  dostawcy narzędzi służących do analizy aktywności
                        w Serwisie oraz marketingu bezpośredniego, dostawcy narzędzi służących do tworzenia stron lądowania i zbierania leadów, dostawcy systemów biurowych, dostawcy oprogramowania do zarządzania projektami, dostawcy oprogramowania służącego komunikacji,
                    </li>
                    <li>
                        uprawnione organy państwowe na mocy obowiązujących przepisów prawa,
                    </li>
                    <li>
                        inne podmioty, których żądanie w zakresie przekazania danych znajdzie uzasadnienie w obowiązujących przepisach prawa.
                    </li>
                </ol>

                <br />

                <h5>CZY PRZEKAZUJEMY DANE OSOBOWE DO PAŃSTW TRZECICH?</h5>

                <p>
                    Co do zasady nie przekazujemy danych osobowych do państw spoza Unii Europejskiej i Europejskiego Obszaru Gospodarczego (EOG). Jeżeli jednak w związku z realizacją usług zajdzie taka konieczność, ocenimy okoliczności i zadbamy o zapewnienie odpowiedniego poziomu bezpieczeństwa danych, tak aby przetwarzanie odbywało się w zgodzie z obowiązującymi regulacjami prawnymi.
                </p>
                <p>
                    Prowadząc Serwis korzystamy z usług i technologii oferowanych przez takie podmioty jak: Facebook, Microsoft, Google , które mają swoje siedziby w Stanach Zjednoczonych i mogą częściowo przetwarzać dane osobowe z wykorzystaniem serwerów zlokalizowanych poza Europejskim Obszarem Gospodarczym (EOG).
                    W świetle przepisów RODO są to tzw. podmioty zlokalizowane w państwach trzecich, względem których należy wykazać zapewnienie odpowiedniego stopnia ochrony lub wzmiankę o właściwych zabezpieczeniach.
                </p>
                <p>
                    Zapewniamy, że ww. podmioty stosują mechanizmy zgodności przewidziane przez RODO (np. certyfikaty) lub standardowe klauzule umowne przyjęte przez Komisje Europejską (art. 46 ust. 2 lit. c RODO). Więcej 
                    informacji na temat zasad przetwarzania danych przez ww. podmioty znajdziesz na stronach www dostawców tych usług.
                </p>

                <h5>CZY PROFILUJEMY TWOJE DANE OSOBOWE?</h5>

                <p>
                    W ramach Serwisu i zastosowanych technologii możemy dokonywać profilowania. Polega ono na wykorzystywaniu danych użytkownika (tj. płeć, wiek, zainteresowania, przybliżona lokalizacja, zachowania w Serwisie) do oceny jego aktywności oraz potencjalnego zainteresowania usługami.
                </p>

                <p>
                    Profilowanie umożliwia personalizowanie oferty i reklam kierowanych do użytkowników, nie ma jednak ma wpływu na zasady i warunki zawieranych umów o świadczenie usług. Przetwarzane informacje maja charakter anonimowy i nie są kojarzone z użytkownikiem zamawiającym usługi. Nie podejmujemy zatem żadnych zautomatyzowanych decyzji, które mogłyby wywołać wobec osób fizycznych skutki prawne lub mogłyby wpłynąć na nie w podobnie istotny sposób. 
                </p>

                <br />

                <h5>CZY WYKORZYSTUJEMY TZW. CIASTECZKA?</h5>

                <p>
                    W Serwisie wykorzystujemy tzw. cookies („ciasteczka”), czyli krótkie informacje tekstowe, zapisywane na komputerze, telefonie, tablecie lub innym urządzeniu użytkownika, które mogą być odczytywane przez nasz system, a także przez systemy należące do innych podmiotów, z których usług korzystamy: Facebooka, Google’a .
                </p>

                <p>
                    Dzięki cookies zbieramy anonimowe dane o wizytach użytkowników w Serwisie, które możemy wykorzystywać do usprawniania funkcjonalności Serwisu, identyfikacji błędów lub działań marketingowych.
                </p>

                <p>
                    Zazwyczaj przeglądarki internetowe domyślnie dopuszczają wykorzystywanie cookies w urządzeniu końcowym. Użytkownicy mogą jednak blokować i ograniczać instalowanie plików cookies według własnego uznania za pomocą ustawień swojej przeglądarki lub przy pomocy innych rozwiązań (darmowych). Podczas Twojej pierwszej wizyty w Serwisie wyświetlimy Ci informację na temat stosowania plików cookies. Jeśli nie zmienisz ustawień swojej przeglądarki wyrazisz zgodę na ich użycie. Więcej informacji na temat tego, w jaki sposób zmienić ustawienia dotyczące plików cookies zakresie na stronie www Twojej przeglądarki internetowej.
                </p>

                <p>
                    Informujemy, że wyłączenie lub ograniczenie obsługi plików cookies może spowodować trudności
                    w korzystaniu ze strony internetowej, np. może spowodować dłuższy okres ładowania się strony czy ograniczenia w korzystaniu z funkcjonalności lub polubieniu strony na Facebooku.
                </p>

                <br />

                <h5>JAK CHRONIMY TWOJE DANE?</h5>

                <p>
                    W celu zapewnienia wysokiego i spójnego stopnia ochrony stosujemy adekwatne do przetwarzania zabezpieczenia środowiska informatycznego, jak również środki techniczno-organizacyjne, wśród których znajdują się miedzy innymi:
                </p>

                <ol>
                    <li>szyfrowanie protokołem TLS,</li>
                    <li>tworzenie kopii bezpieczeństwa,</li>
                    <li>wyposażenie centrów danych w mechanizmy ochrony danych,</li>
                    <li>przeprowadzanie regularnych testów poziomu bezpieczeństwa,</li>
                    <li>monitoring bezpieczeństwa danych osobowych,</li>
                    <li>minimalizowanie ryzyka potencjalnych nadużyć oraz szybkie reagowanie w przypadku ich zaistnienia,</li>
                    <li>wdrażanie polityki ochrony danych,</li>
                    <li>zapewnienie ciągłej poufności, integralności, dostępności i odporności systemów przetwarzania
                        i usług,</li>
                    <li>umożliwienie dostępu do danych osobowych wyłącznie osobom upoważnionym,</li>
                    <li>tworzenie i regularna zmiana haseł dostępu do systemów, w których przetwarzane są dane osobowe. </li>
                </ol>

                <br />

                <h5>JAKIE PRAWA PRZYSŁUGUJĄ OSOBOM, KTÓRYCH DANE PRZETWARZAMY?</h5>

                <p>
                    Osoby, których dane przetwarzamy mają prawo do:
                </p>

                <ol>
                    <li>dostępu do swoich danych osobowych;</li>
                    <li>sprostowania danych osobowych;</li>
                    <li>usunięcia danych osobowych;</li>
                    <li>ograniczenia przetwarzania danych osobowych;</li>
                    <li>wniesienia sprzeciwu wobec przetwarzania danych osobowych;</li>
                    <li>przenoszenia danych osobowych;</li>
                    <li>cofnięcia zgody na przetwarzanie danych (o ile podstawą przetwarzania jest zgoda).</li>
                </ol>
                
                <p>
                    Wymienione powyżej uprawnienia nie mają jednak charakteru absolutnego i w niektórych sytuacjach,
                    po dokonaniu analizy, możemy zgodnie z prawem odmówić ich spełnienia.
                </p>

                <p>
                    Informujemy również, że cofniecie zgody na przetwarzanie danych nie będzie miało wpływu na legalność przetwarzania danych, które miało miejsce na podstawie udzielonej zgody przed jej cofnięciem.
                </p>

                <p>
                    Jeżeli wystąpisz do nas z wnioskiem o wykonanie któregoś z powyższych uprawnień, ustosunkujemy się do niego niezwłocznie, jednak nie później niż w ciągu miesiąca od dnia otrzymania. Jeśli z uwagi na skomplikowany charakter żądania lub liczbę żądań nie będziemy mogli spełnić Twojego żądania w ciągu miesiąca, to spełnimy je w ciągu kolejnych dwóch miesięcy. Uprzednio jednak poinformujemy Cię
                    o zamierzonym przedłużeniu terminu.
                </p>

                <br />

                <h5>JAK MOŻNA ZASKARŻYĆ NIEPRAWIDŁOWOŚCI W PRZETWARZANIU DANYCH OSOBOWYCH?</h5>

                <p>
                    Jeśli uznasz, że Twoje dane osobowe są przetwarzane przez nas niezgodnie z obowiązującym prawem, możesz wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych.
                </p>

                <br />

                <h5>CZY KORZYSTANIE Z SERWISU WIĄŻE SIĘ Z WYSYŁANIEM LOGÓW DO SERWERA?</h5>

                <p>
                    Korzystanie z Serwisu wiąże się z przesyłaniem zapytań do serwera, na którym przechowywana jest strona. Każde zapytanie skierowane do serwera zapisywane jest w logach serwera i przechowywane na serwerze. Logi obejmują m.in. adres IP, datę i czas serwera, informacje o przeglądarce internetowej i systemie operacyjnym.
                </p>
                <p>
                    Dane zapisane w logach serwera nie są kojarzone z konkretnymi osobami korzystającymi ze strony i nie są wykorzystywane przez nas w celu Twojej identyfikacji.
                </p>

                <p>
                    Logi serwera stanowią wyłącznie materiał pomocniczy służący do administrowania stroną, a ich zawartość nie jest ujawniana nikomu poza osobami upoważnionymi do administrowania serwerem.
                </p>

                <br />

                <h5>CZY MOŻEMY ZMIENIAĆ NASZĄ POLITYKĘ PRYWATNOŚCI?</h5>

                <p>
                    Tak. Ochrona danych osobowych jest procesem, który dostosowujemy do bieżących potrzeb i zmieniającej się technologii. Dlatego też, nasza Polityka Prywatności może być uzupełniana lub zmieniania, o czym poinformujemy Cię poprzez zamieszczenie informacji w Serwisie, a w przypadku istotnych zmian zarejestrowanym usługobiorcom wyślemy odrębne powiadomienia drogą elektroniczną.
                </p>

            </div>
        );
    }
}