import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function UserLogin() {

    const navigate = useNavigate();
    const [hasErrors, setHasErrors] = React.useState(false);
    const [form, setForm] = React.useState({
        email: '',
        password: '',
    });

    const handleChange = (event) => {
        
        setForm({
            ...form,
            [event.target.id]: event.target.value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        fetch('api/login?useCookies=false', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: form.email, password: form.password })
         })
        .then(response => response.json())
        .then((result) => {

            if (result.status === 401) {
                setHasErrors(true);
            }
            else {
                setHasErrors(false);

                localStorage.setItem('accessToken', result.accessToken)
                localStorage.setItem('refreshToken', result.refreshToken)

                navigate('/user/companies')
            }
            
         });
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="email">Email</label>
                    <input type="email" className="form-control" id="email" aria-describedby="emailHelp"
                        value={form.email} onChange={handleChange}/>
                    {/*<small id="emailHelp" className="form-text text-muted">Nazwa użytkownika.</small>*/}
                </div>
                <div className="mb-3">
                    <label htmlFor="password">Hasło</label>
                    <input type="password" className="form-control" id="password"
                        value={form.password} onChange={handleChange}/>
                </div>
                <div className="mb-3">
                    <label className="form-check-label" htmlFor="exampleCheck1">Zapomniałeś hasła?</label>
                    <a href="/user/password/forgot">Zresetuj hasło</a>
                </div>
                <button type="submit" className="btn btn-primary">Zaloguj</button>
            </form>
            {
                hasErrors ?
                <div className="alert alert-danger" role="alert">
                        Niepoprawny login lub hasło
                    </div> : null
            }
        </div>
    )
}