import React, { Component } from 'react';
export class TermsOfService extends Component {

    render() {
        return (
            <div>
                <h2>Regulamin</h2>

                <br />

                <h5>§ 1. Postanowienia Ogólne</h5>

                <ol>
                    <li>
                        Niniejszy regulamin określa rodzaj, zakres i warunki świadczenia Usług drogą elektroniczną za pośrednictwem Serwisu, w tym zasady korzystania z Systemu.
                    </li>
                    <li>
                        Właścicielem Serwisu jest „MINDPEAK MACIEJ MUCHA” z siedzibą w Warszawie NIP: 693-192-37-73,  REGON 368134801,
                        adres e-mail: info@stocked.pl
                    </li>
                    <li>
                        Korzystanie z Serwisu i świadczonych za jego pośrednictwem Usług wymaga uprzedniego zapoznania się z treścią Regulaminu i jego akceptacji. Brak akceptacji Regulaminu uniemożliwia złożenie zamówienia i korzystanie z Usług.
                    </li>
                    {/*<li>*/}
                    {/*    Z uwagi na charakter Usług Regulamin nie ma zastosowania do Konsumentów .*/}
                    {/*</li>*/}
                </ol>

                <br />

                <h5>§ 2. Definicje:</h5>

                <p>Pojęciom użytym w niniejszym Regulaminie nadaje się następujące znaczenia:</p>

                <ol>
                    <li>
                        Aplikacja mobilna - oprogramowanie wraz elementami niebędącymi programem komputerowym w wersji na urządzenia mobilne, w ramach którego Usługobiorca może skorzystać z funkcjonalności Systemu ;
                    </li>
                    <li>
                        Cennik - zestawienie informacji o cenach Usług świadczonych przez Usługodawcę dostępne w Serwisie w zakładce „Cennik”/ pod adresem: https://stocked.pl/price-list, stanowiące integralną część Regulaminu;
                    </li>
                    <li>
                        Konsument - osoba fizyczna dokonująca z przedsiębiorcą czynności prawnej niezwiązanej bezpośrednio z jej działalnością gospodarczą lub zawodową oraz przedsiębiorca prowadzący jednoosobową działalność gospodarczą zawierający umowy niemające dla niego charakteru zawodowego, któremu przepisy prawa (w tym ustawa o prawach konsumenta i kodeks cywilny) przyznają uprawnienia o charakterze konsumenckim;
                    </li>
                    <li>
                        Konto Usługobiorcy/Konto - część Serwisu stanowiąca zbiór zasobów i uprawnień przypisanych konkretnemu Usługobiorcy, dostępna po zalogowaniu się przy użyciu loginu i hasła, za pośrednictwem której Usługobiorca ma dostęp do Systemu i może korzystać z Usług;
                    </li>
                    <li>
                        Link aktywacyjny - wygenerowanie na skutek opłaconego zamówienia na Usługę indywidualnie przypisanego do Usługobiorcy kodowanego łącza, którego uzupełnienie o kod dostępu umożliwia dostęp do Konta Usługobiorcy;
                    </li>
                    <li>
                        Okres rozliczeniowy - okres, za który dokonywane są rozliczenia zobowiązań Usługobiorcy
                        z tytułu Usług świadczonych w trybie abonamentowym rozpoczynający się z każdym nowym miesiącem lub rokiem;
                    </li>
                    <li>
                        Opłata abonamentowa - opłata uiszczana przez Usługobiorcę w wysokości określonej w Cenniku tytułem korzystania z Usług w danym Okresie rozliczeniowym;
                    </li>
                    <li>
                        Przedsiębiorca – osoba fizyczna niebędąca Konsumentem, osoba prawna lub jednostka organizacyjna niebędąca osobą prawną, której odrębna ustawa przyznaje zdolność prawną, wykonująca działalność gospodarczą, w tym także wspólnicy spółki cywilnej w zakresie wykonywanej przez nich działalności gospodarczej;
                    </li>
                    <li>
                        Regulamin - niniejszy regulamin świadczenia usług drogą elektroniczną stanowiący wzorzec umowy i będący jednoczenie wypełnieniem obowiązku, o którym mowa w art. 8 ust. 1 pkt 1 Ustawy o świadczeniu usług drogą elektroniczną;
                    </li>
                    <li>
                        RODO - rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia
                        27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych);
                    </li>
                    <li>
                        Serwis - zorganizowana platforma informatyczno-informacyjna umożliwiająca Usługobiorcy korzystanie Usług oraz dostęp do treści cyfrowych opracowanych przez Usługodawcę dostępna pod adresem domeny www.stocked.pl oraz adresami jej subdomen;
                    </li>
                    <li>
                        System - rozwiązanie informatyczne będące oprogramowaniem wraz elementami niebędącymi programem komputerowym dostępne w modelu SaaS (Software as a Service) dostarczane przez Usługodawcę Usługobiorcy za pośrednictwem Serwisu/Aplikacji mobilnej  na podstawie Umowy. ;
                    </li>
                    <li>
                        Świadczenie usługi drogą elektroniczną - wykonanie Usługi świadczonej bez jednoczesnej obecności stron (na odległość) na rzecz Usługobiorcy, poprzez przekaz danych na indywidualne żądanie Usługobiorcy, przesyłanej i otrzymywanej za pomocą urządzeń do elektronicznego przetwarzania, włącznie z kompresją cyfrową, i przechowywania danych, która jest w całości nadawana, odbierana lub transmitowana za pomocą sieci telekomunikacyjnej w rozumieniu ustawy z dnia 16 lipca 2004 r. Prawo telekomunikacyjne;
                    </li>
                    <li>
                        Umowa - umowa o świadczenie Usług drogą elektroniczną zawierana na odległość pomiędzy Usługodawcą a Usługobiorcą, której przedmiot i treść określają postanowienia niniejszego Regulaminu, Cennik oraz informacje o dostępnych Usługach dostępne w Serwisie;
                    </li>
                    <li>
                        Usługi - bezpłatne i odpłatne usługi Świadczone drogą elektroniczną za pośrednictwem Serwisu przez Usługodawcę na rzecz Usługobiorcy, polegające na zapewnieniu przez Usługodawcę Usługobiorcy dostępu do Systemu i jego funkcjonalności oraz jego prawidłowego działania ;
                    </li>
                    <li>
                        Usługobiorca – Przedsiębiorca, który korzysta z Usług na podstawie Umowy w celach związanych bezpośrednio z jego działalnością gospodarczą lub zawodową;
                    </li>
                    <li>
                        Usługodawca – podmiot świadczący Usługi drogą elektroniczną na podstawie Regulaminu, Cennika i  informacji o Usługach dostępnych w Serwisie, tj. 
                        „MINDPEAK MACIEJ MUCHA” z siedzibą w Warszawie NIP: 693-192-37-73,  REGON 368134801
                    </li>
                    <li>
                        Ustawa o świadczeniu usług drogą elektroniczną - ustawa z dnia 18 lipca 2002 r.
                        o świadczeniu usług drogą elektroniczną (tj. z dnia 3 marca 2020 r. (Dz.U.2020.344)
                    </li>
                </ol>

                <br />

                <h5>§ 3. Wymagania techniczne</h5>

                <p>
                    Korzystanie z Serwisu, w tym w szczególności korzystanie z Usług, wymaga spełnienia minimalnych wymagań technicznych niezbędnych do współpracy z Systemem, tj.:
                </p>

                <ol>
                    <li>dysponowanie sprawnym urządzeniem posiadającym połączenie z siecią Internet (np. komputer, tablet, smartphone),</li>
                    <li>stałe łącze internetowe o przepustowości minimum 1 Mb/s,</li>
                    <li>aktualna i poprawnie skonfigurowana wersja przeglądarek internetowych,</li>
                    <li>włączona obsługa plików cookies i Java Script.</li>
                    <li>posiadanie aktywnego adresu poczty elektronicznej,</li>    
                </ol>

                <br />

                <h5>§ 4. Korzystanie z Serwisu</h5>
                <ol>
                    <li>
                        W ramach Serwisu Usługodawca umożliwia Usługobiorcy:
                    </li>
                    <ol>
                        <li>zapoznanie się z informacjami dotyczącymi zakresu i warunków świadczenia Usług,</li>
                        <li>założenie, prowadzenie i likwidację Konta Usługobiorcy,</li>
                        <li>składanie zamówień na Pakiety Usług i korzystanie z Usług,</li>
                        <li>przesłanie wiadomości za pośrednictwem formularza kontaktowego,</li>
                    </ol>

                    <li>
                        Akceptując niniejszy Regulamin, Usługobiorca zobowiązuje się w szczególności do:
                    </li>
                    <ol>
                        <li>respektowania zakazu dostarczania treści o charakterze bezprawnym, przez co należy rozumieć m.in.: zakaz zamieszczania treści obraźliwych, nieprawdziwych, niemoralnych, naruszających dobre obyczaje, propagujących przemoc i nienawiść,</li>
                        <li>niepodejmowania działań zmierzających do zakłócenia funkcjonowania Serwisu i wejścia
                            w posiadanie informacji oraz danych nieprzeznaczonych dla Usługobiorcy,</li>
                        <li>niepodejmowania innych działań, które byłyby niezgodne z obowiązującymi przepisami prawem, dobrymi obyczajami lub naruszałyby prawa i dobra osobiste Usługodawcy oraz osób trzecich.</li>
                    </ol>

                    <li>
                        Usługobiorca zobowiązuje się korzystać z treści zamieszczanych w Serwisie wyłącznie na własny użytek i w celu realizacji zawartej Umowy. Używanie zasobów i funkcji Serwisu w innych celach wymaga odrębnej, uprzedniej i pisemnej zgody Usługobiorcy.
                    </li>

                    <li>
                        W przypadku, gdy Usługobiorca korzystając z Serwisu dopuści się naruszeń Regulaminu lub obowiązujących przepisów prawa, a także gdy w inny sposób działa na szkodę Usługodawcy
                        i/lub osób trzecich, Usługodawca może ograniczyć jego dostęp lub pozbawić go dostępu
                        do Serwisu ze skutkiem natychmiastowym. O zastosowanych środkach Usługobiorca zostanie niezwłocznie poinformowany. Uzyskanie powtórnego dostępu do Serwisu wymaga uprzedniej zgody Usługodawcy.
                    </li>

                    <li>
                        W przypadku zajścia zdarzenia, o którym mowa w ust. 4 powyżej Usługobiorca zobowiązany jest podjąć niezwłocznie działania w celu usunięcia przyczyny ograniczenia lub zablokowania dostępu do Konta Usługobiorcy, w przeciwnym wypadku Usługodawca jest uprawniony do rozwiązania Umowy ze skutkiem natychmiastowym bez wypowiedzenia.
                    </li>

                    <li>
                        Usługodawca świadczy Usługi w sposób nieprzerwany 7 dni w tygodniu z zastrzeżeniem postanowień ust. 7  poniżej.
                    </li>

                    <li>
                        Usługodawca zastrzega sobie prawo do czasowego zawieszenia świadczenia Usług w ramach Systemu w związku z koniecznością przeprowadzenia prac serwisowych lub naprawczych, jak również w związku z modernizacją lub przebudową Systemu. Usługodawca dołoży starań, aby przerwy w świadczeniu Usług nie były uciążliwe dla Usługobiorcom. Informacja o planowanym czasowym zawieszeniu pracy Systemu będzie przekazywana Usługobiorcom drogą elektroniczną na podany przy rejestracji adres e-mail lub za pośrednictwem Konta Usługobiorcy .
                    </li>

                    <li>
                        Usługodawca nie ponosi odpowiedzialności za przerwy w świadczeniu Usług lub zakłócenia w dostępie do Serwisu g wywołane siłą wyższą (pożar, klęski żywiołowe, epidemia), awariami sprzętu Usługobiorcy, przerwami w dostawie prądu czy łączności z Internetem, nieuprawnioną ingerencją Usługobiorców lub osób trzecich czy też błędnym działaniem systemów telekomunikacyjnych i oprogramowania zainstalowanego na sprzęcie komputerowym Usługobiorców.
                    </li>
                </ol>

                <br />

                <h5>§ 5. Konto Usługobiorcy</h5>

                <ol>
                    <li>Rejestracja i prowadzenie Konta Usługobiorcy są bezpłatne.</li>
                    <li>
                        Zarejestrowanie Konta Usługobiorcy jest możliwe poprzez wypełnienie dostępnego w Serwisie formularza rejestracji oraz podanie następujących danych zgodnych ze stanem faktycznym:
                        <ul>
                            <li>Imię, naziwsko, adres, telefon, email,</li>
                            <li>W przypadku przedsiębiorcy: nawę firmy, NIP, REGON,</li>
                        </ul>
                    </li>
                    <li>
                        Do zawarcia umowy prowadzenia Konta pomiędzy Usługodawcą a Usługobiorcą dochodzi z chwilą jego aktywacji przez Usługobiorcę, która następuje poprzez kliknięcie w Link aktywacyjny przesłany przez Usługodawcę na adres e-mail podany przez Usługobiorcę przy rejestracji Konta.
                    </li>
                    <li>Zalogowanie się do Serwisu wymaga podania loginu oraz hasła. Loginem jest adres e-mail Użytkownika. Hasło ustalane jest przez Usługobiorcę w procesie rejestracji Konta.</li>
                    <li>Usługobiorca zobowiązuje się do utrzymania w poufności informacji umożliwiających dostęp do Konta Usługobiorcy. W celu zachowania bezpieczeństwa logowania Usługodawca zaleca zmianę hasła logowania co 30 dni. Usługodawca nie ponosi odpowiedzialności za skutki udostępnienia hasła przez Usługobiorcę osobom trzecim.</li>
                    <li>Konto zakładane jest na czas nieokreślony, przy czym Usługobiorca jest uprawniony do likwidacji Konta w każdym czasie bez podania przyczyny. W tym celu należy wysłać prośbę na adres e-mail: info@stocked.pl </li>
                    <li>Usługodawca jest uprawniony do zablokowania Konta w sytuacji, o której mowa w  § 4 ust. 4  Regulaminu lub usunięcia Konta w sytuacji, o której mowa w  § 4 ust. 5  Regulaminu.</li>
                    <li>Zmiana, bądź odzyskiwanie hasła możliwa jest poprzez funkcje odzyskiwania, bądź zmianę hasła dostępną w stronie logowania lub w panelu użytkownika.</li>
                </ol>
                
                <br />

                <h5>§ 6. Korzystanie z Usług</h5>

                <ol>
                    <li>
                        Korzystanie z Usług jest możliwie po uprzednim zarejestrowaniu w Serwisie Konta Usługobiorcy, zaakceptowaniu Regulaminu i Polityki Prywatności, zapoznaniu się z Cennikiem i informacjami o dostępnych Usługach zamieszczonymi w Serwisie oraz w przypadku usług odpłatnych – po uiszczeniu Opłaty abonamentowej.</li>
                    <li>
                        Usługobiorcy mogą korzystać z Usług bezpłatnie – w ramach darmowego dostępu do Usług przez 7 dni oraz odpłatnie – w ramach wybranego wariantu Usług odpłatnych zgodnie z Cennikiem.
                    </li>
                    <li>
                        Okres abonamentowy świadczenia Usług odpłatnych wynosi odpowiednio 30 dni / 365 dni, a wybór wariantu odpłatnych Usług dokonywany jest przez Usługobiorcę w trakcie składania zamówienia na Usługę. Czas świadczenia Usługi w ramach wykupionego abonamentu zaczyna biec od dnia aktywowania Usługi.
                    </li>
                    <li>
                        Umowa o świadczenie Usług odpłatnych zostaje zawarta z chwilą zatwierdzenia przez Usługobiorcę złożonego zamówienia na Usługę poprzez wybranie przycisku „zamawiam i płacę”.
                    </li>
                    <li>
                        Zamówienia, których nie uda się potwierdzić w ciągu 7 dni zostaną anulowane.
                    </li>
                    <li>
                        Zamówienia na Usługi są przyjmowane i rejestrowane przez Usługodawcę w dniach od poniedziałku do piątku w godzinach 9:00 – 16:00  przez cały rok kalendarzowy.
                    </li>
                    <li>
                        Zamówione Usługi bezpłatne zostaną aktywowane w terminie 1h od potwierdzenia ich zamówienia przez Usługobiorcę, zaś zamówione Usługi odpłatne w terminie 2h
                        od zaksięgowania na koncie Usługodawcy Opłaty abonamentowej, której wysokość uzależniona jest od wybranego wariantu Usług.
                    </li>
                    <li>
                        Usługobiorca, zarówno w trakcie trwania, jak i po zakończeniu Usługi bezpłatnej, ma możliwość zawarcia Umowy odpłatnej poprzez formularz zakupowy.  O zakończeniu Usługi bezpłatnej i możliwości zawarcia Umowy odpłatnej Usługodawca poinformuje Usługobiorcę drogą elektroniczną.
                    </li>
                    <li>
                        Usługobiorca w dowolnym momencie, przed upływem bieżącego Okresu rozliczeniowego, ma prawo zmiany Opłaty abonamentowej i wybranego wariantu Usług. W tym celu Usługobiorca powinien skorzystać z formularza w panelu użytkownika. Nowy okres rozliczeniowy w ramach nowego wariantu Usług zaczyna biec po zakończeniu bieżącego Okresu rozliczeniowego.
                    </li>
                    <li>
                        Umowa o świadczenie odpłatnej Usługi zawierana jest na czas jej trwania określony w wybranym wariancie Usług i Cenniku dostępnych w Serwisie.
                    </li>
                </ol>

                <br />

                <h5>§ 7. Płatności</h5>

                <ol>
                    <li>
                        Usługobiorca zobowiązany jest zapłacić za Usługę odpłatną z góry zgodnie z Cennikiem w terminie 7 dni  od potwierdzenia zamówienia Usługi, w przeciwnym wypadku Usługodawca ma prawo
                        do odstąpienia od Umowy i anulowania zamówienia.
                    </li>
                    <li>
                        Jeżeli dany wariant Usług nie jest wyszczególniony w obowiązującym Cenniku, to Usługobiorcę obowiązuje cennik zindywidualizowany (odpowiadający treści odrębnej umowy z Usługodawcą).
                    </li>
                    <li>
                        Usługodawca umożliwia dokonanie płatności w następujących formach:  Przelew tradycyjny, Blik, Szybki przelew, przy pomocy kart kredytowych, oraz innych metod płatności obsługiwanych przez bramkę płatności firmy PayU. 
                    </li>
                    <li>
                        Momentem zapłaty jest data zaksięgowania wpłaty na rachunku bankowym Usługodawcy.
                    </li>
                    <li>
                        Koszty związane z realizacją płatności ponosi Usługobiorca.
                    </li>
                    <li>
                        Usługodawca wystawi Usługobiorcy fakturę w oparciu o dane wskazane podczas rejestracji Konta Usługobiorcy. W przypadku, gdy Usługobiorca jest płatnikiem VAT powinien, podczas rejestracji bądź w zamówieniu, podać swój nr NIP i zaznaczyć czy ma być wystawiona Faktura VAT. Usługodawca dostarczy fakturę Usługobiorcy na podany przez niego adres poczty elektronicznej w  terminie 7 dni od jej wystawienia.
                    </li>
                    <li>
                        Usługobiorca wyraża zgodę na otrzymywanie faktur od Usługodawcy drogą elektroniczną, zgodnie z art. 106 n ust. 1 Ustawy z dnia 11 marca 2004 r. o podatku od towarów i usług.
                    </li>
                    <li>
                        Wszelkie zmiany dotyczące Opłat abonamentowych będą ogłaszane w Serwisie poprzez publikację aktualnego Cennika.
                    </li>
                    <li>
                        Zmiana Cennika nie wpływa na zmianę wysokości Opłat abonamentowych uiszczonych przez Usługobiorców na podstawie Umowy zawartej przed opublikowaniem zmienionego Cennika.
                    </li>
                </ol>

                <br />

                <h5>§ 8. Rozwiązywanie umów</h5>
                <p>Rozwiązanie Umowy o świadczenie Usług nastąpi:</p>
                <ol>
                    <li>w odniesieniu do Usługi prowadzenia Konta Usługobiorcy – w chwili złożenia przez Usługobiorcę dyspozycji usunięcia Konta (poprzez wprowadzenie zmian w Koncie Usługobiorcy lub przesłanie deklaracji usunięcia konta na adres e-mail Usługodawcy) lub wskutek usunięcia Konta Usługobiorcy przez Usługodawcę w związku z rozwiązaniem Umowy z przyczyn określonych w § 4 ust. 5 Regulaminu,</li>
                    <li>w odniesieniu do Usługi świadczonej bezpłatnie – po upływie terminu jej trwania i w związku z brakiem wniesienia Opłaty abonamentowej za Usługę odpłatną;</li>
                    <li>w odniesieniu do Usługi świadczonej odpłatnie - z upływem okresu, na jaki Usługa została wykupiona i braku kontynuacji Umowy na dalszy Okres rozliczeniowy, przez co należy rozumieć brak uiszczenia Opłaty abonamentowej za kolejny Okres rozliczeniowy.</li>
                    <li>Usługobiorca może rozwiązać Umowę bez podania przyczyn poprzez usunięcie Konta Usługobiorcy w Systemie. Wówczas Umowa wygasa z końcem bieżącego Okresu rozliczeniowego, a wcześniej dokonane opłaty nie podlegają zwrotowi.</li>
                    <li>Usługodawcy przysługuje prawo do rozwiązania Umowy bez zachowania okresu wypowiedzenia w przypadku:</li>
                    <ol>
                        <li>podania przez Usługobiorcę nieprawdziwych i niekompletnych danych, które są niezbędne do realizacji Umowy,</li>
                        <li>uzasadnionego podejrzenia lub stwierdzenia faktu korzystania przez Usługobiorcę z Usług w sposób niezgodny z Regulaminem lub w sposób naruszający powszechnie obowiązujące przepisy prawa, bądź w sposób naruszający prawa osób trzecich, w tym w szczególności w sytuacji naruszania przez Usługobiorcę zobowiązań określonych w § 4 ust. 2 Regulaminu czy w sytuacji, o której mowa w § 4 ust. 5 Regulaminu</li>
                    </ol>
                </ol>

                <br />

                <h5>§ 9. Tryb postępowania reklamacyjnego</h5>

                <ol>
                    <li>
                        W przypadku wystąpienia zastrzeżeń co do funkcjonowania Systemu lub nieprawidłowości
                        w świadczeniu Usług przez Usługodawcę, Usługobiorca może złożyć reklamację przesyłając ją na następujący adres e-mail: info@stocked.pl
                    </li>
                    <li>
                        W zgłoszeniu reklamacyjnym należy wskazać:
                        <ol>
                            <li>login/adres e-mail przypisany Usługobiorcy oraz dane kontaktowe,</li>
                            <li>przedmiot reklamacji – wskazanie nieprawidłowości wraz ze wskazaniem terminu ich wystąpienia i czasu trwania,</li>
                            <li>proponowany sposób rozstrzygnięcia reklamacji.</li>
                        </ol>
                    </li>
                    <li>
                        Usługodawca przed rozpatrzeniem złożonej reklamacji, może zwrócić się do Usługobiorcy o jej uzupełnienie, jeżeli podane przez Użytkownika informacje, o których mowa w ust. 2 wymagają doprecyzowania w celu prawidłowego rozpatrzenia reklamacji.
                    </li>
                    <li>
                        Rozpatrzenie reklamacji nastąpi niezwłocznie, przy czym nie później niż w terminie 14 dni od dnia otrzymania zgłoszenia reklamacyjnego przez Usługodawcę, a sposób rozstrzygnięcia Usługodawca przekaże Usługobiorcy drogą elektroniczną na adres e-mail przypisany do Konta Usługobiorcy.
                    </li>
                    <li>
                        Wszelkie inne uwagi dotyczące funkcjonowania Serwisu oraz pytania dotyczące Usług należy kierować za pośrednictwem poczty elektronicznej na następujący adres mailowy: info@stocked.pl.
                    </li>
                </ol>

                <br />

                <h5>§ 10. Wsparcie techniczne</h5>

                <ol>
                    <li>
                        W trakcie obowiązywania Umowy Usługobiorca ma możliwość skorzystania ze wsparcia technicznego Usługodawcy w zakresie związanym z korzystaniem z Usług w ramach Opłaty abonamentowej.
                    </li>
                    <li>
                        W celu skorzystania ze wsparcia technicznego należy wysłać zgłoszenie problemu
                        na adres e-mail: info@stocked.pl ze wskazaniem rodzaju nieprawidłowości i błędów w Systemie.
                    </li>
                    <li>
                        Wsparcie i pomoc techniczna świadczone są przez Usługodawcę od poniedziałku do piątku (z wyjątkiem dni ustawowo wolnych od pracy na terenie Rzeczypospolitej Polskiej) w godzinach od 9:00 do  16:00.
                    </li>
                    <li>
                        Wsparcie techniczne Usługodawcy nie ma zastosowania do:
                        <ol>
                            <li>elementów, które podlegają samodzielnej modyfikacji przez Usługobiorcę,</li>
                            <li>błędów, które powstały w wyniku nieuprawnionej ingerencji Usługobiorcy w ustawienia Usługi,</li>
                            <li>problemów wynikających z niespełnienia przez Usługobiorcę wymogów technicznych,  które są niezbędne do korzystania z Usługi.</li>
                        </ol>
                    </li>
                </ol>

                <br />

                <h5>§ 11. Ochrona danych osobowych i poufność</h5>

                <ol>
                    <li>Administratorem danych Usługobiorców jest Usługodawca.</li>
                    <li>
                        Administrator danych przetwarza dane osobowe Usługobiorców tj. imię i nazwisko, adres prowadzenia działalności gospodarczej, adres poczty elektronicznej, nr telefonu, NIP, nr konta bankowego  w celu umożliwienia Usługobiorcom korzystania z Systemu, w tym w celu świadczenia Usług na rzecz Usługobiorcy.
                    </li>
                    <li>
                        Podanie przez Usługobiorców danych osobowych jest dobrowolne, jednakże bez ich podania korzystanie z poszczególnych Usług może okazać się niemożliwe.
                    </li>
                    <li>Usługodawca dokłada starań, aby zapewnić bezpieczeństwo i poufność danych osobowych,
                            w szczególności poprzez dokonywanie czynności przetwarzania zgodnie z obowiązującymi przepisami prawa (RODO i regulacje sektorowe) oraz zapewnienie odpowiednich środków bezpieczeństwa.
                    </li>
                    <li>Więcej informacji na temat ochrony danych osobowych Usługobiorców wraz z informacjami na temat polityki cookie’s znajduje się w Polityce Prywatności dostępnej w Serwisie.</li>
                    <li>
                        Przetwarzanie przez Usługodawcę danych dotyczących osób innych niż Usługobiorca następuje na podstawie Umowy powierzenia przetwarzania danych, stanowiącej załącznik
                        nr 1 do Regulaminu.
                    </li>
                </ol>

                <br />

                <h5>§ 12. Prawa na dobrach niematerialnych</h5>

                <ol>
                    <li>Usługodawca zastrzega, że wszelkie zarówno Serwis jak i System zawierają treści chronione prawem własności intelektualnej (np. elementy graficzne, logotypy, programy komputerowe.</li>
                    <li>Korzystanie z Serwisu nie oznacza nabycia jakichkolwiek praw na dobrach niematerialnych
                        do utworów znajdujących się w Serwisie. Bez pozyskania uprzedniej zgody Usługodawcy, zabronione jest kopiowanie, rozpowszechnianie, wykorzystywanie oraz modyfikowanie jakichkolwiek elementów składowych Serwisu czy Systemu.</li>
                    <li>Usługodawca udziela Usługobiorcy jedynie licencji niewyłącznej (dalej: „Licencja”) uprawniającej do korzystania z Serwisu i Systemu w sposób zgodny z jego przeznaczeniem, w tym na zasadach określonych w Regulaminie, Cenniku oraz innych komunikatach zamieszczonych w Serwisie, a udzielona Usługobiorcy licencja nie uprawnia do udzielania dalszych licencji (sublicencji).</li>
                    <li>Licencja udzielana jest przez Usługodawcę bez ograniczeń terytorialnych na czas określony odpowiadający okresowi świadczenia Usługi dostępu do Systemu i wygasa wraz z usunięciem Konta Usługobiorcy.</li>
                </ol>

                <br />


                <h5>§ 13. Postanowienia końcowe</h5>

                <ol>
                    <li>Regulamin jest dostępny bezpłatnie w Serwisie dla każdego Usługobiorcy.</li>
                    <li>Usługodawca zastrzega sobie prawo wprowadzania zmian w treści Regulaminu z zastrzeżeniem ochrony praw nabytych.</li>
                    <li>Usługodawca poinformuje Usługobiorców o zmianach Regulaminu nie później niż 14 dni przed ich planowanym wejściem w życie poprzez: wyświetlenie komunikatu i publikację nowego Regulaminu w Serwisie oraz przesłanie informacji o planowanej zmianie drogą elektroniczną na adres e-mail przypisany do Konta Usługobiorcy. Brak sprzeciwu Usługobiorcy wobec proponowanych zmian w terminie 14 dni od powiadomienia jest równoznaczny z akceptacją nowego Regulaminu. W przypadku sprzeciwu Umowa ulega rozwiązaniu z dniem poprzedzającym wejście w życie nowego Regulaminu, co skutkuje również rozwiązaniem umowy o prowadzenie Konta Usługobiorcy.</li>
                    <li>Usługodawca może na mocy odrębnej umowy zawartej z Usługobiorcą odmiennie uregulować zasady korzystania z Systemu. W takiej sytuacji w razie kolizji postanowienia ww. umowy mają pierwszeństwo przed postanowieniami Regulaminu.</li>
                    <li>Niniejszy Regulamin podlega prawu polskiemu. Wszelkie spory na gruncie niniejszego regulaminu będą rozwiązywane w drodze polubownych negocjacji, a w przypadku gdy nie dojdzie do porozumienia - przed sądem powszechnym właściwym ze względu na siedzibę Usługodawcy.</li>
                    <li>W sprawach nieuregulowanych w Regulaminie zastosowanie znajdą przepisy powszechnie obowiązującego prawa polskiego.</li>
                    <li>Treść niniejszego Regulaminu dostępna jest w Serwisie oraz w Aplikacji mobilnej.</li>
                    <li>Regulamin obowiązuje od dnia 01-02-2024.</li>
                </ol>

            </div>
        )
    }
}