import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Modal } from 'bootstrap';
export function PasswordReset() {

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [form, setForm] = React.useState({
        email: searchParams.get('email'),
        password: '',
        confirmPassword: '',
        token: decodeURIComponent(searchParams.get('token')).replaceAll(' ', '+') 
    });
      

    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.id]: event.target.value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        fetch('api/password/reset', {
            method: 'POST',
            body: JSON.stringify({ email: form.email, token: form.token, password: form.password, confirmPassword: form.confirmPassword })
            , headers: {
                'Content-Type': 'application/json'
            },
        })
        .then((result) => {
            var myModal = new Modal(document.getElementById('confirmation-widnow'));
            myModal.show();
        });
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <input type="hidden" className="form-control" id="token" 
                    value={form.token} />
                <input type="hidden" className="form-control" id="email"
                    value={form.email} />
                <div className="mb-3">
                    <label htmlFor="password">Nowe hasło</label>
                    <input type="password" className="form-control" id="password" aria-describedby="passwordHelp"
                        value={form.password} onChange={handleChange} />
                    {/*<small id="emailHelp" className="form-text text-muted">Nazwa użytkownika.</small>*/}
                </div>
                <div className="mb-3">
                    <label htmlFor="confirmPassword">Potwierdź hasło</label>
                    <input type="password" className="form-control" id="confirmPassword" aria-describedby="confirmPasswordHelp"
                        value={form.confirmPassword} onChange={handleChange} />
                    {/*<small id="emailHelp" className="form-text text-muted">Nazwa użytkownika.</small>*/}
                </div>
                <button type="submit" className="btn btn-primary">Ustaw hasło</button>
            </form>
            <div id="confirmation-widnow" className="modal" tabindex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Hasło zostało ustawione</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Teraz możesz się zalogować przy pomocy nowego hasła.</p>
                        </div>
                        <div className="modal-footer">
                            <a href="/user/login" className="btn btn-primary">Przejdź do logowania</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}