import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SearchBar = () => {

    const navigate = useNavigate();
    const [searchInput, setSearchInput] = useState('');
    const [companies, setCompanies] = useState([]);

    //const handleSearchSubmit = (e) => {
    //    if (e.key === 'Enter') {
    //        navigate('/company/' + searchInput + '/posts', { state: { id: searchInput } })
    //    }
    //};

    const handleSearchInputChange = (e) => {

        setSearchInput(e.target.value)

        if (e.target.value.length > 1) {

            fetch('/api/company?searchInput=' + e.target.value)
                .then(response => response.json())
                .then((result) => {
                    setCompanies(result)
                });
        }
    };

    const handleSearchSuggestion = (ticker) => {
        setSearchInput(ticker);
        setCompanies([])

        navigate('/company/' + ticker.toLowerCase() + '/posts', { state: { id: ticker } })
    };

    return (
        <div className="searchBar">
            <input
                type="text"
                className="form-control"
                placeholder="Wpisz ticker..."
                onChange={e => handleSearchInputChange(e)}
                value={searchInput}
                onBlur={() => {
                    setTimeout(() => {
                        setCompanies([])
                    }, 100);
                }}
            />
            {/*onKeyPress={handleSearchSubmit}*/}
            <div className="suggestion-items">
            {
                companies && companies.map((c, index) =>
                    <div key={c.ticker} onClick={() => handleSearchSuggestion(c.ticker)}>
                        {c.name}
                    </div>
                    )
                
                }
            </div>
        </div>
    )
}

export default SearchBar;