import React, { Component } from 'react';
export class FooterMenu extends Component {

    render() {
        return (
            <div className="container">
                <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                    <p class="col-md-4 mb-0 text-body-secondary">©2024 Stocked</p>

                    {/*<a href="/" class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">*/}
                    {/*    <svg class="bi me-2" width="40" height="32"><use xlink: href="#bootstrap"></use></svg>*/}
                    {/*</a>*/}

                    <ul class="nav col-md-4 justify-content-end">
                        <li class="nav-item"><a href="/privacy-policy" class="nav-link px-2 text-body-secondary">Polityka prywatności</a></li>
                        <li class="nav-item"><a href="/terms-of-service" class="nav-link px-2 text-body-secondary">Regulamin</a></li>
                        <li class="nav-item"><a href="/contact" class="nav-link px-2 text-body-secondary">Kontakt</a></li>
                    </ul>
                </footer>
            </div>
        );
    }
}