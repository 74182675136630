import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

export function CompanyPosts() {

    const { id } = useParams()
    const [companyPosts, setCompanyPosts] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [prevId, setPrevId] = useState("");
    const shouldLoad = useRef(true);

    useEffect(() => {

        async function loadCompanyPosts() {

            if (Object.keys(setCompanyPosts).length === 0 || prevId !== id) {

                setIsLoading(true);

                fetch('/api/company/' + id + '/posts')
                    .then(response => response.json())
                    .then((result) => {
                        setCompanyPosts(result)
                        setIsLoading(false);
                    });

                setPrevId(id);
            }
        }

        if (shouldLoad.current || prevId !== id) {
            shouldLoad.current = false;
            loadCompanyPosts();
        }
    }, [id, companyPosts, isLoading, prevId])


    const formatDate = (dateStr) => {
        var date = new Date(dateStr);
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();

    };

    if (Object.keys(companyPosts).length === 0) {
        return null
    }

    function PostItemElement({ i, index }) {

        return (
            <div className={`post-item ${i.postType.toLowerCase()}`} >
                <div className="list-group">
                    <a href={i.url} className="list-group-item list-group-item-action flex-column align-items-start">
                        <div className="row">
                            <div>
                                <span className={`badge ${i.postType === 'News' ? 'text-bg-primary' : 'text-bg-warning'}`}>{i.postType.toLowerCase()}</span>
                            </div>
                            <div>
                                {/*{*/}
                                {/*    (i.imageUrl) &&*/}
                                {/*    <div className="col-md-3">*/}
                                {/*        <img src={i.imageUrl} alt="" />*/}
                                {/*    </div>*/}
                                {/*}*/}
                                <div>
                                    <h5 style={{ marginTop: '5px' }}><b>{i.title}</b></h5>
                                </div>
                                <p className="post-description">{i.description}</p>
                                <div className="row">
                                    <div className="w-100 text-end align-text-bottom">
                                        <div>
                                            <small>{formatDate(i.publishedAt)}</small>
                                        </div>
                                        <div>
                                            <small>Źródło: {i.sourceWebsiteName}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <br />
            </div>
        );
    }

    return (
        <div>
            {
                companyPosts.map((i, index) =>
                    <PostItemElement key={index} i={i} index={index} />
                )
            }
        </div>
    );
}