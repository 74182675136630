import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';
import { httpClient } from 'httpClient'

export function UserEmailNotifications() {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const shouldLoad = useRef(true);
    const [emailNotificationsEnabled, setEmailNotificationsEnabled] = React.useState(false);

    const handleChange = (event) => {
        setEmailNotificationsEnabled(event.target.checked);
    };

    useEffect(() => {
        async function loadForm() {

            httpClient.send('/api/user/notifications', {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('accessToken')
                }
            })
            .then(result => result.json().catch(err => { return {} }) )
            .then((result) => {

                if (result.status === 401) {
                    navigate("user/login")
                }

                setEmailNotificationsEnabled(result.emailNotificationsEnabled);
                setIsLoading(false);
            });
        }


        if (shouldLoad.current) {
            setIsLoading(true);

            loadForm()

            setIsLoading(false);
            shouldLoad.current = false;
        }
    },[])

    
    
    const handleSubmit = (event) => {
        event.preventDefault();

        console.log(emailNotificationsEnabled)

        httpClient.send('/api/user/notifications', {
            method: 'POST',
            body: JSON.stringify({ emailNotificationsEnabled: emailNotificationsEnabled }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('accessToken')
            }
        })
        .then((result) => {
            
        });
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <h3>Powiadomienia mailowe</h3>
                <div className="form-check form-switch">
                    <div className="mb-3">
                        <input className="form-check-input" type="checkbox" role="switch" id="emailNotificationsEnabled"
                            checked={emailNotificationsEnabled} onChange={handleChange} />
                        <label className="form-check-label" htmlFor="emailNotificationsEnabled">
                            Włącz tę opcję, jeśli chcesz otrzymywać przed sesją i po sesji na swoją skrzynkę pocztową
                            wiadomości dotyczyące wybranych przez Ciebie spółek giełdowych.
                        </label>
                    </div>
                </div>
                <input type="submit" className="btn btn-primary" value="Zapisz zmiany" />
            </form>
        </div>
    )
}