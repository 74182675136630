import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

export function AdminUnmatchedPosts() {

    const navigate = useNavigate();
    const [unmatchedPosts, setUnmatchedPosts] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const shouldLoad = useRef(true);

    const handleCheckbox = (event) => {

        var id = event.target.value;
        console.log('checkbox', id);
        setUnmatchedPosts(prev =>
            prev.map((s, index) => ({ ...s, isSelected: s.id === id ? !s.isSelected : s.isSelected }))
        )
    }

    const handleRowClick = (post) => {

        var id = post.id;
        console.log('row', id);

        setUnmatchedPosts(prev =>
            prev.map((s, index) => ({ ...s, isSelected: s.id === id ? !s.isSelected : s.isSelected }))
        )
    }

    async function loadUnmatchedPosts() {

        if (Object.keys(setUnmatchedPosts).length === 0) {

            setIsLoading(true);

            fetch('api/admin/posts/unmatched', {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('accessToken')
                }
            })
                .then(response => response.json())
                .then((result) => {
                    setUnmatchedPosts(result)
                    setIsLoading(false);
                });
        }
    }
    

    const handleSubmit = (event) => {
        event.preventDefault();


        fetch('api/admin/posts/unmatched', {
            method: 'DELETE',
            body: JSON.stringify(unmatchedPosts.filter(x => x.isSelected == 1)),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('accessToken')
            }
            })
            .then((result) => {
                setUnmatchedPosts({});
                loadUnmatchedPosts();
        });
    };

    useEffect(() => {

        

        if (shouldLoad.current) {
            shouldLoad.current = false;
            loadUnmatchedPosts();
        }
    }, [unmatchedPosts, isLoading])

    if (Object.keys(unmatchedPosts).length === 0) {
        return null
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div>
                    {/*<input type="submit" className="btn btn-primary" value="Zapisz zmiany" />*/}
                    <input type="submit" className="btn btn-danger" value="Usuń wybrane" />
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Title</th>
                            <th scope="col">Url</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            /*onChange = { handleCheckbox }*/

                            unmatchedPosts.map((i, index) =>
                                <tr onClick={() => handleRowClick(i)} key={index} className={i.isSelected ? "table-primary" : ""} >
                                    <th scope="row">
                                        <input type="checkbox" value={i.id} defaultChecked={i.isSelected} /> 
                                    </th>
                                    <td>{i.title}</td>
                                    <td>{i.url}</td>
                                    <td>
                                        {
                                            i.companies.map((j, index) => 
                                                <span>{j}<br /></span>
                                            )
                                        }
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </form>
        </div>
    )
}