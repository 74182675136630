import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'bootstrap';
import { httpClient } from 'httpClient'

export function UserCompanies() {

    const [userCompanies, setUserCompanies] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const shouldLoad = useRef(true);

    const handleCheckbox = (event) => {

        var id = event.target.value;

        setUserCompanies(prev =>
            prev.map((s, index) => ({ ...s, isTracked: s.id === id ? !s.isTracked : s.isTracked }))
        )
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        httpClient.send('api/user/companies', {
            method: 'POST',
            body: JSON.stringify(userCompanies.filter(x => x.isTracked == 1)),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('accessToken')
            }
            })
            .then((result) => {
                var myModal = new Modal(document.getElementById('confirmation-widnow'));
                myModal.show();
            });
    };

    useEffect(() => {

        async function loadUserCompanies() {

            if (Object.keys(setUserCompanies).length === 0) {

                setIsLoading(true);

                httpClient.send('/api/user/companies', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken')
                    }
                })
                .then(response => response.json())
                .then((result) => {
                    setUserCompanies(result)
                    setIsLoading(false);
                });
            }
        }

        if (shouldLoad.current) {
            shouldLoad.current = false;
            loadUserCompanies();
        }
    }, [userCompanies, isLoading])

    if (Object.keys(userCompanies).length === 0) {
        return null
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div>
                    <input type="submit" className="btn btn-primary" value="Zapisz zmiany" />
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nazwa</th>
                            <th scope="col">Ticker</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            userCompanies.map((i, index) =>
                                <tr key={index} className={i.isTracked ? "table-primary" : "" } >
                                    <th scope="row">
                                        <input type="checkbox" onChange={handleCheckbox} value={i.id} defaultChecked={i.isTracked} />
                                    </th>
                                    <td>{i.name}</td>
                                    <td>{i.ticker}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </form>
            <div id="confirmation-widnow" className="modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Zmiany zostały zapisane</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Teraz na stronie głównej wyświetlana są artykuły dotyczące wybranych przez Ciebie spółek.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Zostań na stronie</button>
                            <a href="/" className="btn btn-primary">Przejdź do artykułów</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}