import { About } from "./components/About";
import { Home } from "./components/Home";
import { PrivacyPolicy } from "./components/PrivacyPolicy";
import { TermsOfService } from "./components/TermsOfService";
import { Contact } from "./components/Contact";
import { Posts } from "./components/posts/Posts"
import { CompanyPosts } from './components/companies/CompanyPosts'
import { UserRegister } from "./components/users/Register";
import { UserLogin } from "./components/users/Login";
import { UserCompanies } from "./components/users/UserCompanies";
import { UserEmailNotifications } from "./components/users/UserEmailNotifications"
import { AdminUnmatchedPosts } from "./components/admin/AdminUnmatchedPosts";
import { ForgotPassword } from "./components/users/ForgotPassword";
import { PasswordReset } from "./components/users/PasswordReset";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/about',
    element: <About />
  },
  {
      path: '/posts',
      element: <Posts />
  },
  {
     path: '/company/:id/posts',
     element: <CompanyPosts />
  },
  {
     path: '/user/new',
     element: <UserRegister />
  },
  {
    path: '/user/login',
    element: <UserLogin />
  },
  {
    path: '/user/password/forgot',
    element: <ForgotPassword />
  },
  {
    path: '/user/password/reset',
    element: <PasswordReset />
  },
  {
    path: '/user/companies',
    element: <UserCompanies />
  },
  {
    path: '/admin/posts/unmatched',
    element: <AdminUnmatchedPosts />
  },
  {
    path: '/user/notifications',
    element: <UserEmailNotifications />
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />
  },
  {
    path: '/terms-of-service',
    element: <TermsOfService />
  },
  {
    path: '/contact',
    element: <Contact />
  }
];

export default AppRoutes;
