import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';

export function ForgotPassword() {

    const navigate = useNavigate();
    const [form, setForm] = React.useState({
        email: ''
    });

    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.id]: event.target.value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        fetch('api/password/forgot', {
            method: 'POST',
            body: JSON.stringify({ email: form.email }),
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then((result) => {
            var myModal = new Modal(document.getElementById('confirmation-widnow'));
            myModal.show();
        });
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="email">Email</label>
                    <input type="email" className="form-control" id="email" aria-describedby="emailHelp"
                        value={form.email} onChange={handleChange} />
                    {/*<small id="emailHelp" className="form-text text-muted">Nazwa użytkownika.</small>*/}
                </div>
                <button type="submit" className="btn btn-primary">Resetuj hasło</button>
            </form>
            <div id="confirmation-widnow" className="modal" tabindex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Hasło zostało zresestowane</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Sprawdź skrzynkę pocztową bo wysłaliśmy do Ciebie link do ustawienia nowego hasła.</p>
                        </div>
                        <div className="modal-footer">
                            <a href="/user/login" className="btn btn-primary">Przejdź do logowania</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}