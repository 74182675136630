import React, { Component } from 'react';

export class About extends Component {
    

    render() {
        return (
            <div>
                <h3>Problem</h3>
                <p>
                    Czy też mieliście problem z odfiltrowaniem informacji giełdowych, które są naprawdę dla Was ważne i
                    dotyczą się tylko spółek, którymi jesteście zainteresowani?
                    Codziennie jesteśmy bombardowani niezliczoną ilością komunikatów, z których tylko nieliczne mają znaczenie.
                    W serwisie Stocked masz dostęp do komunikatów ESPI, ESP oraz serwisów, Parkiet, PAP Biznes, Forsal oraz
                    wielu innych serwisów branżowych. 
                </p>
                <p>
                    Stocked powstał, aby pomóc rozwiazać Wam ten problem. 
                </p>
                <br /><br />
                <h2>Wybór spółek</h2>
                <p>
                    Po zalogowaniu Stocked pozwoli Wam w zakładce <b>Moje spółki</b> na wybór spółek,
                    o których informacje chcecie śledzić.
                    Po zapisaniu zmian na stronie głównej pozostaną tylko wiadomości dotyczące wybranych spółek. 
                </p>
                <br />
                <p>
                    <img alt="Moje spółki" src="/images/my-stocks.png" />
                </p>
                <br /><br />
                <h2>Powiadomienia E-mail</h2>
                <p>
                    Jeśli nie macie czasu na śledzenie wiadomości na bieżąco, Stocked pomoże Wam wysyłając dwa razy
                    dziennie wiadomość na Waszą skrzynkę z listą artykułów o Waszych spółkach. Przed i po każdej sesji.
                </p>
                <p>
                    Wystarczy włączyć tę opcję w zakładce <b>Powiadomienia</b>
                </p>
                <br />
                <p>
                    <img alt="Powiadomienia" src="/images/notifications.png" />
                </p>
                <br /><br />
                <p>
                    Zarejestruj się już dziś.&nbsp;
                    <a aria-current="page" href="/user/new">Rejestracja</a> 
                </p>
            </div>
        );
    }
}
