import { useNavigate } from 'react-router-dom';
import { NavItem } from 'reactstrap';

export default function UserNavItem() {

    const navigate = useNavigate();

    const handleLogout = (event) => {
        event.preventDefault();

        fetch('api/user/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('accessToken')
            }
        })
        .then((result) => {
            localStorage.clear();
            navigate('/');
        });
    };

    if (localStorage.getItem("accessToken")) {
        return (
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <a className="nav-link active" aria-current="page" href="/user/companies">Moje spółki</a>
              </NavItem>
              <NavItem>
                <a className="nav-link active" aria-current="page" href="/user/notifications">Powiadomienia</a>
              </NavItem>
                <NavItem>
                    <button className="nav-link active" aria-current="page" onClick={handleLogout}>Wyloguj</button>
              </NavItem>
            </ul>
        )
    }
        
    return (
        <ul className="navbar-nav flex-grow">
            <NavItem>
                <a className="nav-link active" aria-current="page" href="/about">Jak działa Stocked?</a>
            </NavItem>
        <NavItem>
            <a className="nav-link active" aria-current="page" href="/user/login">Logowanie</a>
        </NavItem>
        <NavItem>
            <a className="nav-link active" aria-current="page" href="/user/new">Rejestracja</a>
        </NavItem>
        </ul>
    )
}