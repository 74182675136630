import React, { Component } from 'react';
import { Posts } from './posts/Posts';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
        <div>
            <Posts />
        </div>
    );
  }
}
