import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';
export function UserRegister() {

    const navigate = useNavigate();
    const [hasErrors, setHasErrors] = React.useState(false);
    const [errors, setErrors] = React.useState([""]);
    const [form, setForm] = React.useState({
        email: '',
        password: '',
    });

    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.id]: event.target.value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        fetch('api/register', {
            method: 'POST',
            body: JSON.stringify({ email: form.email, password: form.password })
            , headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(result => result.json().catch(err => { return {} }))
        .then((result) => {

            setHasErrors(false);

            if (result.status === 400) {

                var errorMessages = [""];

                for (var error in result.errors) {
                    if (result.errors.hasOwnProperty(error)) {
                        var errorMessage = result.errors[error][0];
                        errorMessages.push(errorMessage);
                    }
                }

                setHasErrors(true);
                setErrors(errorMessages);

                return;
            }
                
            var myModal = new Modal(document.getElementById('confirmation-widnow'));
            myModal.show();
        });
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="email">Email</label>
                    <input type="email" className="form-control" id="email" aria-describedby="emailHelp"
                        value={form.email} onChange={handleChange}/>
                    {/*<small id="emailHelp" className="form-text text-muted">Nazwa użytkownika.</small>*/}
                </div>
                <div className="mb-3">
                    <label htmlFor="password">Hasło</label>
                    <input type="password" className="form-control" id="password"
                        value={form.password} onChange={handleChange}/>
                </div>
                {/*<div className="form-group form-check">*/}
                {/*    <input type="checkbox" className="form-check-input" id="exampleCheck1">*/}
                {/*        <label className="form-check-label" for="exampleCheck1">Check me out</label>*/}
                {/*</div>*/}
                <button type="submit" className="btn btn-primary">Zarejestruj</button>
            </form>

            {
                hasErrors ?
                    <div className="alert alert-danger" role="alert">
                        {errors.map((i, index) => <div key={index}>{i}</div> )}
                    </div> : null
            }

            <div id="confirmation-widnow" className="modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Konto zostało utworzone</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Możesz się teraz zalogować.</p>
                        </div>
                        <div className="modal-footer">
                            <a href="/user/login" className="btn btn-primary">Przejdź do logowania</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}