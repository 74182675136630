import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom' 
import { httpClient } from 'httpClient'


export function Posts() {


    const navigate = useNavigate();
    const [posts, setPosts] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const shouldLoad = useRef(true);

    useEffect(() => {

        async function loadCompany() {

            if (Object.keys(setPosts).length === 0) {

                setIsLoading(true);

                var accessToken = localStorage.getItem('accessToken');

                if (accessToken) {

                    httpClient.send('/api/user/posts', {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + localStorage.getItem('accessToken')
                        }
                    })
                        .then(response => response.json())
                        .then((result) => {

                            if (result.status === 401) {
                                navigate("user/login")
                            }

                            setPosts(result)
                            setIsLoading(false);
                        });

                } else {

                    httpClient.send('/api/posts', {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    })
                    .then(response => response.json())
                    .then((result) => {
                        setPosts(result)
                        setIsLoading(false);
                    });
                }
            }
        }

        if (shouldLoad.current) {
            shouldLoad.current = false;

            loadCompany();
        }
    }, [posts, isLoading])

    const formatDate = (dateStr) => {

        var date = new Date(dateStr);
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();

    };

    if (Object.keys(posts).length === 0) {
        return null
    }

    function PostItemElement({ i, index }) {

        return (
            <div className={`post-item ${i.postType.toLowerCase()}`} >
                <div className="list-group">
                    <a href={i.url} className="list-group-item list-group-item-action flex-column align-items-start">
                        <div className="row">
                            <div>
                                <span className={`badge ${i.postType === 'News' ? 'text-bg-primary' : 'text-bg-warning'}`}>{i.postType.toLowerCase()}</span>
                            </div>
                            <div>
                                {/*{*/}
                                {/*    (i.imageUrl) &&*/}
                                {/*    <div className="col-md-3">*/}
                                {/*        <img src={i.imageUrl} alt="" />*/}
                                {/*    </div>*/}
                                {/*}*/}
                                <div>
                                    <h5 style={{ marginTop: '5px' }}><b>{i.title}</b></h5>
                                </div>
                                <p className="post-description">{i.description}</p>
                                <div className="row">
                                    <div className="w-50" style={{ marginTop: 'auto' }}>
                                        <small>Walory:
                                            <ul className="list-unstyled">
                                                {
                                                    i.companies.map((j, index) =>
                                                        <li key={index}>
                                                            <Link to={`company/${j.ticker}/posts`}>{j.name}</Link>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </small>
                                    </div>
                                    <div className="w-50 text-end align-text-bottom">
                                        <div>
                                            <small>{formatDate(i.publishedAt)}</small>
                                        </div>
                                        <div>
                                            <small>Źródło: {i.sourceWebsiteName}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <br/>
            </div>
        );
    }

    return (
        <div>
            <div className="container">
                <h2>Wiadomości giełdowe z dnia {new Date().toLocaleDateString()} </h2>
                <div className="row post-list-items">
                    {
                        posts.map((i, index) => (

                            <PostItemElement key={index} i={i} index={index} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
