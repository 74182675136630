export const httpClient = {
    send
};

function send(url, requestOptions) {
    return fetch(url, requestOptions)
        .catch(handleCatch)
        .then(handleRefreshToken)
        .then(response => {
            if (response.status === 401) {

                return fetch(url,
                {
                        ...requestOptions,
                        headers: {
                           'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + localStorage.getItem('accessToken')
                        }
                });
            }
            return response;
        })
}



function handleCatch(error) {
    console.log('error', error);
}

async function handleRefreshToken(response) {

    if (response.status === 401) {

       await fetch('api/refresh', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ refreshToken: localStorage.getItem('refreshToken') })
        })
        .then(refreshTokenResponse => refreshTokenResponse.json())
        .then((refreshTokenResponse) => {

            localStorage.setItem('accessToken', refreshTokenResponse.accessToken)
            localStorage.setItem('refreshToken', refreshTokenResponse.refreshToken)
        });
    }

    return response;
}